.flowLayoutWrapper {
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow-y: hidden;
}

.flow_header {
  width: 100%;
  padding: 12px;
  border-bottom: 1px solid #eaeef4;
  display: flex;
  align-items: center;
  position: fixed;
  background: #ffffff;
  z-index: 1000;
}

.logo-styling {
  width: 130px;
  height: 40px;
}

.flow_layout_body {
  min-height: calc(100vh - 70px);
  margin-top: 70px;
  overflow-y: scroll;
  @media all and (min-width: 1021px) {
    padding-bottom: 40px;
  }
}

.stepper_placement_wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
}

@media all and (max-width: 1020px) {
  .flow_header {
    flex-direction: column;
  }
  .flow_logo_wrapper {
    align-self: flex-start;
  }
  .flow_stepper_wrapper {
    margin-left: 0;
    align-self: center;
    width: 100%;
  }
  .flow_layout_body {
    height: 100vh; // calc(100vh - 185px);
    margin-top: 105px;
    overflow-y: scroll;
    padding-bottom: 80px;
  }
  .flow_whats_next_layout {
    margin-top: 70px !important;
  }
}
