.gc_body_wrapper {
  display: flex;
  flex-direction: column;
}

.top_img_banner {
  height: 210px;
  position: relative;
}

.gc_banner_header {
  color: #003595;
  font-size: 28px;
  font-weight: 600;
  text-align: left;
}

.gc_banner_subtext {
  color: #5f6267;
  font-size: 14px;
  font-weight: 400;
  text-align: left;
}

.gc_bottom_body {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 0 auto;
}

.gc_header_text {
  color: #003595;
  font-weight: 700;
  font-size: 1.2em;
}

.gc_subtext {
  color: #5f6267;
  font-size: 0.8em;
  text-align: left;
}

.gc_access_token_wrapper {
  display: flex;
  align-items: center;
  width: 100%;
}

.gc_overlay {
  position: absolute;
  top: 80px;
  left: 50px;
  width: 700px;
}

.gc_bottom_header {
  display: flex;
  align-items: center;
}

.gc_tests_display_cell {
  color: #5f6267;
  font-weight: 500;
  font-size: 0.9em;
  text-align: left;
  margin-top: 16px;
}

.gc_card_wrapper {
  border: 1px solid #d9d9d9;
  border-radius: 8px;
  background-color: #ffffff;
}

.gc_access_code_wrapper {
  background-color: rgb(236, 241, 251, 0.8);
  border-radius: 4px;
  padding: 10px 12px;
  text-align: start;
  font-size: 13px;
  margin-right: 12px;
  font-weight: 600;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 50%;
  cursor: pointer;
}

@media all and (max-width: 1095px) {
  .gc_body_wrapper {
    height: calc(100vh - 260px);
  }
  .top_img_banner {
    height: auto;
    position: relative;
  }
  .gc_overlay {
    top: 60px;
    left: 50px;
    width: 500px;
  }
}

@media all and (max-width: 800px) {
  .gc_overlay {
    top: 20px;
    left: 50px;
    width: 400px;
  }
  .gc_banner_header {
    font-size: 22px;
  }
  .gc_banner_subtext {
    font-size: 14px;
  }
  .gc_access_token_wrapper {
    flex-direction: column;
    width: 100%;
  }
}

@media all and (max-width: 600px) {
  .gc_banner_header {
    font-size: 18px;
  }
  .gc_banner_subtext {
    font-size: 12px;
    width: 275px;
  }
}

@media all and (max-width: 560px) {
  .gc_bottom_body {
    margin: 0;
    width: 100%;
  }
  .gc_overlay {
    top: 14px;
    left: 45px;
    width: inherit;
  }
  .gc_banner_header {
    font-size: 14px;
  }
  .gc_banner_subtext {
    font-size: 10px;
    width: 210px;
  }
  .gc_subtext {
    width: 100%;
  }
  .top_img_banner {
    height: 120px;
  }
}
