.tests_body_wrapper {
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  width: 60%;
  margin: 18px auto 0 auto;
  padding: 20px 90px;
  border-radius: 8px;
}

.blue_banner_top {
  width: 100%;
  margin: 0 auto;
  align-items: center;
}

.tests_info_cell {
  display: flex;
  justify-content: space-between;
  width: 85%;
  margin: 10px auto 0 auto;
  text-align: center;
}

.tests_title {
  color: #003595;
  font-size: 22px;
  font-weight: 600;
}

.reports_main_title {
  font-size: 16px;
  font-weight: 700;
}

.reports_subtitle {
  color: #5f6267;
  font-size: 12px;
}

.genetic_counseling_wrapper {
  background-color: rgb(236, 241, 251, 0.5);
  padding: 16px;
  margin: 18px;
  border-radius: 8px;
  display: flex;
}

.genetic_counseling_cell {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.tests_secondary_title {
  color: #5f6267;
  font-weight: 600;
  font-size: 20px;
}

.tests_page_divider {
  width: 100%;
  height: 1px;
  background-color: #d9d9d9;
}

.cr_canvas_wrapper {
  height: 80% !important;
  margin: 80px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
}

.cr_canvas_body {
  margin: 32px auto;
  font-size: 14px;
  line-height: 2.5;
  font-weight: 500;
  text-align: center;
  width: 80%;
}

.tests_page_cell {
  display: flex;
  justify-content: space-between;
}

.reports_card_header {
  align-items: center;
  padding: 12px 18px;
}

.genetic_counseling_status_cell {
  display: flex;
  margin: 12px 0 0 12px;
  align-items: center;
}

@media all and (min-width: 1500px) {
  .tests_body_wrapper {
    max-width: 1000px;
  }
}

@media all and (max-width: 1400px) {
  .tests_body_wrapper {
    padding: 30px;
  }
}

@media all and (max-width: 1095px) {
  .tests_bottom_body {
    width: 100%;
    padding: 18px 32px;
  }
  .blue_banner_bottom {
    width: 100%;
    padding: 0;
  }
  .tests_title {
    margin: 12px 0;
  }
  .genetic_counseling_status_cell {
    margin: 24px 0 0 0;
  }
  .tests_body_wrapper {
    width: 90%;
    padding: 18px;
  }
  .blue_banner_top {
    padding: 0 32px;
  }
}

@media all and (max-width: 800px) {
  .tests_page_cell {
    flex-direction: column;
  }
  .reports_card_header {
    align-items: flex-start;
  }
  .genetic_counseling_cell {
    width: 100%;
  }
  .reports_main_title {
    font-size: 14px;
  }
  .genetic_counseling_status_cell {
    margin: 12px 0 0 0;
  }
}
