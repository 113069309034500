.error_page_wrapper {
  display: flex;
  justify-content: space-between;
  height: calc(100vh - 70px);
}

.error_left_side {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 200px;
}

.error_img_styling {
  width: 100%;
  height: 100%;
  object-fit: fill;
}

.error_main_title {
  font-size: 32px;
  font-weight: 600;
  color: #003595;
  margin-bottom: 22px;
}

.error_subtext {
  font-size: 18px;
  color: #434345;
  font-weight: 300;
  line-height: 30px;
}

.error_sub_title {
  font-size: 18px;
  font-weight: 400;
  margin-bottom: 26px;
}

.error_right_side {
  width: 100%;
  margin-top: 5px;
}

@media all and (max-width: 1080px) {
  .error_page_wrapper {
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    height: calc(100vh - 70px);
  }
  .error_left_side {
    height: 100%;
    margin-top: 50px;
  }
  .error_right_side {
    height: 50%;
  }
}
