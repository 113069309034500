.settings_page_title {
  font-size: 28px;
  font-weight: 600;
  color: #003595;
  text-align: left;
  margin-top: 30px;
}

.settings_title_subtext {
  font-size: 12px;
  color: #5f6267;
  text-align: left;
}

.settings_card_body_wrapper {
  display: flex;
  flex-direction: column;
  margin-top: 12px;
  margin-bottom: 50px;
}

.card {
  border: 1px solid #dadee4;
  border-radius: 8px;
  padding: 12px 24px;
}

.settings_data_display {
  display: flex;
  flex-direction: column;
  margin-right: 60px;
}

.data_display_top_margin {
  margin-top: 18px;
}

.data_display_right_margin {
  margin-right: 60px;
}

.settings_switch_wrapper {
  width: fit-content;
  padding: 24px;
  display: flex;
  align-items: center;
}

.settings_consent_title {
  font-size: 14px;
  font-weight: 600;
}

.settings_switch {
  align-self: baseline;

  .form-check-input:checked {
    background-color: #003595;
  }
}

.settings_consent_cell {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 16px;
  text-align: left;
}

.settings_tab {
  height: 175px;
  overflow-y: scroll;
}

@media all and (max-width: 1020px) {
  .settings_tab {
    height: 100%;
  }
}
