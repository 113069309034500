.radio-selection-wrapper {
  display: flex;
  border-radius: 8px;
  padding: 8px 18px;
  font-size: 14px;
  width: 100%;
  align-items: center;
}

.radio-right-margin {
  margin: 0 18px 0 0;
}

.radio-right-margin:last-child {
  margin: 0;
}

.radio-bottom-margin {
  margin: 0 0 18px 0;
}

.radio-bottom-margin:last-child {
  margin: 0;
}

.radio-selection-border {
  border: 1px solid #dadee4;
}

.selection-bar-disabled {
  color: #acaeb2;
}
