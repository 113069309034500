.title_card_wrapper {
  border-radius: 8px;
  padding: 24px 32px;
  background-color: #ffffff;
}

.title_card_title {
  text-align: left;
  font-size: 22px;
  font-weight: 600;
  color: #5f6267;
}

@media all and (max-width: 1020px) {
  .title_card_wrapper {
    width: 100%;
    height: 100%;
  }
}
