/* A space for common css shared across multiple components */

.fs-sm {
  font-size: 1rem * 0.875; // TODO: replace with bootstrap variable
}
.fs-xs {
  font-size: 1rem * 0.75; // TODO: replace with custom bootstrap variable
}

.form-control:focus {
  border: 1px solid #003595;
  box-shadow: none !important;
}

.flex-legend {
  @media all and (max-width: 1025px) {
    width: 100%;
  }
  @media all and (min-width: 1026px) {
    width: inherit;
  }
}

.header_footer_layout_body {
  padding: 0 18px;
  // height: 100%;
}
