.header_footer_layout_wrapper {
  height: 100vh;
  background-color: #f7f8fb;
  overflow: hidden;
}

.hf_layout_body {
  height: calc(100vh - 210px);
  overflow: scroll;
  display: flex;
  justify-content: center;
}

.hf_layout_content {
  height: inherit;
}

@media all and (max-width: 1020px) {
  .hf_layout_body {
    height: calc(100vh - 70px);
    width: 100%;
    flex-direction: column;
  }
}

@media all and (max-width: 800px) {
  .header_footer_layout_wrapper {
    overflow-y: hidden;
    height: calc(100vh - 80px);
  }
  .hf_layout_body {
    height: calc(100vh - 150px);
  }
  .hf_layout_content {
    overflow: scroll;
  }
}
