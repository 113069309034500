.user_logout_wrapper {
  display: flex;
  flex-direction: column;
}

@media all and (max-width: 1020px) {
  .user_logout_wrapper {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 18px;
  }
}
