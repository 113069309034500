.dropdownToggleStyling {
  .controlUnfocused {
    background-color: #ffffff;
    border-color: #dee2e6;
    color: black;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    font-size: 1rem;
    border-radius: 0.375rem;
  }
  .controlFocused {
    @extend .controlUnfocused;
    border: 1px solid #003595 !important;
    box-shadow: none !important;
  }
}

.dropdownToggleError {
  .controlUnfocused {
    background-color: #ffffff !important;
    border-color: red !important;
    color: black !important;
    display: flex;
    width: 160px !important;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    border-radius: 0.375rem !important;
  }
  .controlFocused {
    @extend .controlUnfocused;
  }
}

@media all and (max-width: 1020px) {
  .dropdownToggleStyling {
    width: 100%;
    .controlUnfocused {
      width: 100% !important;
    }
    .controlFocused {
      @extend .controlUnfocused;
    }
  }
  .dropdownToggleError {
    .controlUnfocused {
      width: 100% !important;
    }
    .controlFocused {
      @extend .controlUnfocused;
    }
  }
}
