.order_checkout_wrapper {
  display: flex;
  justify-content: space-evenly;
}

.checkout_right_side {
  padding-right: 18px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 50px 50px 0 0;
}

.user_form_wrapper {
  display: flex;
  flex-direction: column;
  padding: 30px 100px 30px 0;
  align-items: flex-start;
  max-width: 1200px;
}

.order_checkout_header {
  font-size: 22px;
  font-weight: 600;
}

.order_checkout_header_spacing {
  margin-left: 18px;
}

.order_input_cell {
  display: flex;
  margin: 18px 0 0 12px;
}

.login_or_divider {
  display: flex;
  align-items: center;
  align-self: center;
  margin: 12px 0;
}

.or_line {
  background-color: #7c7e83;
  height: 1px;
  width: 200px;
  margin: 0 5px;
}

.order_cell_divider {
  background-color: #dadee4; // same as clinician portal
  height: 1px;
  width: 100%;
  margin: 18px 5px;
  flex-shrink: 0;
}

.billing_checkout_wrapper {
  background-color: #ecf1fb;
  border: 1px solid #eeeeee;
  border-radius: 8px;
  padding: 40px 60px;
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
}

.questionnaire_wrapper {
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin: 30px auto 50px auto;
  max-width: 1100px;
  overflow-y: hidden;
}

.question_cell {
  margin: 32px 18px;
  text-align: left;
  display: flex;
  justify-content: space-between;
}

.question_wording {
  font-size: 14px;
  width: 700px;
  margin-right: 50px;
}

.pit_flow_title {
  font-size: 26px;
  font-weight: 600;
  color: #003595;
  align-self: flex-start;
}

.review_and_submit_cell {
  border: 1px solid #dadee4;
  padding: 12px;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  justify-content: flex-start;
  overflow-y: scroll;
  height: 550px;
}

.review_tiny_label {
  font-size: 12px;
  color: #5f6267;
  align-self: flex-start;
}

.price_overview_section {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: baseline;
}

.price_overview_wrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;
  font-size: 14px;
  padding: 0 32px 0 18px;
}

.billing_info_wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.shaded_consent_wrapper {
  background-color: #ecf1fb;
  font-size: 12px;
  font-weight: 400;
  overflow-y: scroll;
  height: 300px;
  border-radius: 8px;
}

.custom_stripe_styling {
  width: 100%;
  background-color: #ffffff;
  padding: 24px;
  border-radius: 8px;
}

.icon_text_wrapper {
  margin: 24px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.whats_next_icon {
  margin-bottom: 12px;
  font-size: 32px;
  color: #003595;
}

.whats_next_text {
  font-size: 16px;
  font-weight: 400;
  width: 300px;
  color: #5f6267;
  line-height: 1.6;
}

.sign_up_wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-left: 18px;
}

.signup_logged_in_wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}

.form_inputs_section {
  margin-left: 18px;
}

.checkout_radio_wrapper {
  display: flex;
  margin: 18px 0 0 16px;
  text-align: left;
  align-items: center;
}

.checkout_btn_wrapper {
  margin-top: 18px;
  display: flex;
  justify-content: flex-end;
}

.required_error_styling {
  color: red;
  text-align: left;
  &:not(.fs-xs):not(.fs-sm) {
    font-size: 1rem * 0.625;
  }
}

.review_form_input_cell {
  display: flex;
  margin: 10px 0 0 18px;
}

.review_patient_data_cell {
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 0 40px 0 18px;
}

.patient_info_header {
  display: flex;
  justify-content: space-between;
  padding: 0 18px;
  align-items: center;
}

.subtext_styling {
  color: #5f6267;
  text-align: left;
}

.gc_canvas_wrapper {
  height: 90% !important;
  margin: 30px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  padding: 18px 24px;
}

.gc_canvas_body {
  padding: 32px;
  border: 1px solid #acaeb2;
  border-radius: 8px;
  margin: 18px auto 32px auto;
  font-size: 14px;
  font-weight: 500;
  width: 50%;
}

.offcanvas-body {
  flex-grow: 0 !important;
}

.uneditable_input_wrapper {
  border: 1px solid #acaeb2;
  border-radius: 8px;
  padding: 8px;
  font-size: 14px;
}

.whats_next_body {
  height: inherit;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.marked_styling {
  padding: 10px;

  h1 {
    font-size: 18px !important;
    margin-bottom: 22px;
  }
  h2 {
    font-size: 14px !important;
  }
}

.disabled_stripe_card {
  opacity: 0.5;
  pointer-events: none;
}

.checkout-genome-logo {
  background-color: #ecf1fb;
  width: 300px;
}

.white-checkout-genome-logo {
  width: 300px;
  height: 40px;
}

.dna_consent_styling {
  border: 2px solid blue !important;
}

@media all and (max-width: 1025px) {
  .order_checkout_wrapper {
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
    margin: 24px;
  }
  .checkout_right_side {
    width: 100%;
    margin: 0;
    padding-right: 0;
  }
  .checkout_left_side {
    width: 100%;
    margin-left: 32px;
  }
  .user_form_wrapper {
    padding: 0;
    margin: 0 auto;
  }
  .sign_up_wrapper {
    margin-left: 0;
  }
  .order_input_cell {
    flex-direction: column;
    margin: 0 !important;
  }
  .form_inputs_section {
    margin: 0;
    width: 100%;
  }
  .order_checkout_header_spacing {
    margin-left: 0px;
    margin-top: 24px;
  }
  .checkout_radio_wrapper {
    margin-left: 0;
  }
  .questionnaire_wrapper {
    padding: 24px;
    margin: 0 auto;
  }
  .review_patient_info_wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .review_form_input_cell {
    display: flex;
    margin: 10px 18px;
    flex-direction: column;
    width: 100%;
  }
  .patient_info_header {
    padding: 0;
  }
  .question_cell {
    flex-direction: column;
  }
  .question_wording {
    width: auto;
    margin: 0 0 18px 0;
    text-align: center;
  }
  .gc_canvas_body {
    width: auto;
    padding: 24px;
    margin: 24 auto;
  }
  .review_and_submit_cell {
    height: 100%;
  }
}

@media all and (max-width: 1020px) {
  .checkout_left_side {
    margin-left: 0;
  }
}

@media all and (max-width: 800px) {
  .checkout_btn_wrapper {
    justify-content: center;
  }
  .billing_checkout_wrapper {
    padding: 20px;
  }
  .login_or_divider {
    align-self: auto;
  }
  .review_patient_data_cell {
    flex-direction: column;
  }
  .gc_canvas_wrapper {
    border-radius: 0;
    height: 100% !important;
    margin: 0;
    padding: 0;
  }
  .gc_canvas_body {
    margin: 0;
  }
  .checkout-genome-logo {
    width: 95%;
    object-fit: contain;
  }
  .white-checkout-genome-logo {
    width: 220px;
    object-fit: contain;
  }
  .pit_flow_title {
    font-size: 18px;
  }
}
