.order_card_wrapper {
  border: 1px solid #d9d9d9;
  border-top: 0;
  border-radius: 4px;
  width: 550px;
}

.order_top_half {
  padding: 18px 32px;
  display: flex;
  align-items: center;
  border-top: 10px solid #35cbd9;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.order_card_wrapper:hover {
  cursor: pointer;
  background-color: #f6f7fb;
}

.dna_icon_wrapper {
  width: 50px;
  height: 50px;
  border: 3px solid #35cbd9;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.order_card_header {
  display: flex;
  flex-direction: column;
  align-items: baseline;
  margin-left: 22px;
}

.order_bottom_half {
  border-top: 1px solid #d9d9d9;
  display: flex;
  justify-content: space-between;
  padding: 18px 32px;
  align-items: baseline;
}

.order_status_cell {
  background-color: rgb(95, 98, 103, 0.3);
  color: rgb(95, 98, 103);
  border-radius: 60%;
  padding: 5px 8px;
  font-weight: 600;
  font-size: 12px;
  border-radius: 20px;
  margin: 3px auto 0 auto;
}

.order_status_cell_success {
  background-color: rgb(23, 123, 33, 0.2);
  color: rgb(23, 123, 33);
  border-radius: 60%;
  padding: 5px 8px;
  font-weight: 600;
  font-size: 12px;
  border-radius: 20px;
  margin: 3px auto 0 auto;
}

@media all and (max-width: 1020px) {
  .order_card_wrapper {
    width: 100%;
  }
}

@media all and (max-width: 560px) {
  .order_card_wrapper {
    width: 100%;
  }
  .order_top_half {
    flex-direction: column;
  }
  .dna_icon_wrapper {
    margin-bottom: 8px;
  }
  .order_bottom_half {
    flex-direction: column;
  }
  .order_card_header {
    margin-left: 0;
  }
}
