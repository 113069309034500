.stepper-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
}

.line-styling {
  height: 4px;
  border-radius: 20px;
}

.icon-with-label {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  white-space: nowrap;
}

.stepper_label {
  font-size: 10px;
  font-weight: 600;
}

@media all and (max-width: 1020px) {
  .stepper-wrapper {
    font-size: 14px;
    width: 100%;
  }
}

@media all and (max-width: 800px) {
  .stepper-wrapper {
    font-size: 14px;
    width: 100%;
  }
  .icon-with-label {
    &:first-child {
      width: inherit;
    }
  }
  .stepper_label {
    font-size: 10px;
  }
}
