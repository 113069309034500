.header-wrapper {
  display: flex;
  padding: 10px;
  background-color: #fff;
  box-shadow: 0 -6px 10px 5px rgba(0, 0, 0, 0.3);
  z-index: 1;
}

.logo-styling {
  width: 100px;
  height: auto;
}

.nav_link_styling {
  font-weight: 600;
  font-size: 16px;
  margin-right: 50px;
  border-bottom: 3px solid transparent;
}

.nav_link_styling:hover,
.nav_link_styling:active,
.nav_link_styling:focus {
  border-bottom: 3px solid #003595;
  cursor: pointer;
  color: #003595;
}

.nav_link_active {
  border-bottom: 3px solid #003595;
  color: #003595;
}

.mobile_navbar_list {
  margin-left: 100px;
}

.navbar_user_logout {
  margin-right: 18px;
}

@media all and (max-width: 800px) {
  .mobile_navbar_list {
    margin-left: 50px;
  }
}
