.label_styling {
  font-weight: 700;
  margin: 0 0 20px 2px;
  font-size: 13px;
  margin-bottom: 8px;
}

.mask_input_wrapper {
  border-radius: 0.375rem !important;
  padding: 6px !important;
  border: 1px solid #ced4da !important;
}

.mask_input_wrapper:active,
.mask_input_wrapper:focus {
  border: 1px solid #003595 !important;
  outline: none !important;
}

.mask_input_wrapper:disabled {
  background-color: #e9ecef !important;
}

.mask_input_error {
  border-radius: 0.375rem !important;
  padding: 6px !important;
  border: 1px solid red !important;
}

@media all and (max-width: 1020px) {
  .mask_input_wrapper {
    width: 100% !important;
  }
  .mask_input_error {
    width: 100% !important;
  }
}
