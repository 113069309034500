.data_display_wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 8px;
}

.data_label {
  font-size: 12px;
  color: #5f6267;
  font-weight: 400;
  text-align: left;
}

.data_value {
  font-size: 14px;
  font-weight: 400;
  text-align: left;
}
