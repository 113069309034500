.login-page-wrapper {
  display: flex;
  flex-direction: row;
  height: calc(100vh - 70px);
}

.login-right-half {
  display: flex;
  flex-direction: column;
  // margin: 0 auto;
  width: 50%;
}

.right-half-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 70%;
}

.login-img {
  width: 100%;
  object-fit: cover;
  height: 100%;
}

.login-title {
  font-size: 42px;
  color: rgb(0, 53, 149);
  line-height: 1.2;
  font-weight: 600;
  margin-bottom: 42px;
}

@media all and (max-width: 1020px) {
  .login-page-wrapper {
    display: flex;
    flex-direction: column-reverse;
  }
  .login-img {
    object-fit: fill;
  }
  .login-right-half {
    display: flex;
    flex-direction: column;
    height: 50%;
    width: 100%;
  }
  .login-left-half {
    height: 50%;
  }
  .login-title {
    font-size: 32px;
    margin-top: 30px;
  }
}
