.label_styling {
  font-weight: 700;
  margin: 0 0 20px 2px;
  font-size: 12px;
  margin-bottom: 8px;
  text-align: left;
}

.label_error {
  color: red;
}

.form_input_spacing {
  margin: 0 8px 0 0;
}

@media all and (max-width: 1020px) {
  .form_input_spacing {
    margin: 24px 0 0 0;
  }
}

@media all and (max-width: 560px) {
  .form_input_spacing {
    margin: 24px 24px 0 0;
    width: 100%;
  }
}
