.footer_styling {
  border-top: 1px solid #dadee4;
  display: flex;
  flex-direction: column;
  font-size: 10px;
  position: fixed;
  left: 0;
  bottom: 0;
  padding: 10px;
  align-items: center;
  width: 100%;
  background-color: #ffffff;
  text-align: left;
}

.footer_top {
  display: flex;
  align-items: center;
  width: 70%;
  justify-content: space-between;
  margin-bottom: 12px;
}

.footer_bottom {
  color: #acaeb2;
  width: 70%;
  text-align: start;
}

@media all and (max-width: 1020px) {
  .footer_styling {
    position: relative;
  }
  .footer_top {
    width: 100%;
  }
  .footer_bottom {
    width: 100%;
    line-height: 1;
  }
  .footer_styling {
    font-size: 8px;
  }
}
