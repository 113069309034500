.test-details {
  border-top: 1px solid #acaeb2;
  display: flex;
  justify-content: space-between;
  margin: 18px 0;
  padding-top: 5px;
}

.home_user_card {
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  border-radius: 8px;
  padding: 24px;
  width: 325px;
  margin-bottom: 18px;
  align-items: center;
}

.home_body_wrapper {
  display: flex;
  margin: 32px 0;
}

.home_page_title {
  font-size: 32px;
  font-weight: 600;
  text-align: start;
  margin-top: 30px;
  color: #003595;
}

.name_avatar {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  padding: 10px;
  background-color: #09b0bb;
  color: #ffffff;
  font-size: 32px;
  font-weight: 600;
  padding: 13px;
}

.home_body_top {
  display: flex;
}

.home_body_bottom {
  display: flex;
  flex-direction: column;
  margin-left: 18px;
}

@media all and (max-width: 1020px) {
  .home_body_wrapper {
    flex-direction: column;
  }
  .home_body_top {
    flex-direction: column;
    align-items: center;
  }
  .home_body_bottom {
    flex-direction: row;
    margin: 18px 0 0 0;
  }
  .home_user_card {
    width: 100%;
  }
  .test_card_body {
    width: auto;
  }
  .home_user_card {
    margin: 0 18px 0 0;
  }
}

@media all and (max-width: 700px) {
  .home_body_bottom {
    flex-direction: column;
  }
  .home_user_card {
    margin: 0 0 18px 0;
  }
}
