.label_styling {
  font-weight: 700;
  margin: 0 0 20px 2px;
  font-size: 12px;
  margin-bottom: 8px;
  text-align: left;
}

.label_error {
  color: red;
}

.form_input_spacing {
  margin: 0 8px 0 0;
}

.address_input_styling {
  border: 1px solid #dee2e6;
  padding: 5.5px;
  border-radius: 6px;
  width: 100%;
}

@media all and (max-width: 1020px) {
  .form_input_spacing {
    margin: 24px 0 0 0;
  }
}
