@import '~bootstrap/scss/bootstrap';
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

.App {
  text-align: center;
  font-family: 'Roboto', sans-serif;
  height: 100vh;
  overflow-y: hidden;
}

@media all and (max-width: 1020px) {
  .App {
    height: auto;
  }
}
