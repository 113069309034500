.primaryButtonStyling {
  background-color: #003595;
  font-size: 14px;
  font-weight: 600;
  min-width: 150px;
  padding: 12px 24px;
  cursor: pointer;
}

.secondaryButtonStyling {
  background-color: #ffffff;
  color: #003595;
  font-size: 14px;
  font-weight: 600;
  min-width: 150px;
  padding: 12px 24px;
  border: 2px solid #003595;
  cursor: pointer;
}

.secondaryButtonStyling:hover {
  color: #ffffff !important;
  background-color: #003595 !important;
}

.dangerButtonStyling {
  background-color: #ffffff;
  color: #bb0909;
  font-size: 14px;
  font-weight: 600;
  min-width: 150px;
  padding: 12px 24px;
  border: 2px solid #bb0909;
  cursor: pointer;
}

.dangerButtonStyling:hover {
  color: #ffffff;
  background-color: #bb0909;
  border: 2px solid #bb0909;
}

.loginButtonStyling {
  background-color: #ffffff;
  color: #003595;
  font-size: 18px;
  font-weight: 500;
  min-width: 150px;
  padding: 8px 24px;
  margin-right: 18px;
  border: 2px solid #003595;
}

.loginButtonStyling:hover {
  color: #ffffff;
  background-color: #003595;
}

.btn:disabled,
.btn.disabled,
fieldset:disabled .btn {
  color: #acaeb2;
  border: 2px solid #acaeb2;
  background-color: #ffffff;
}

@media all and (max-width: 800px) {
  .primaryButtonStyling {
    width: 100%;
  }
  .secondaryButtonStyling {
    width: 100%;
  }
  .dangerButtonStyling {
    width: 100%;
  }
}
